<template>
    <el-form :model="comicData" class="audit-dialog-content" label-width="90px">
        <el-form-item label="漫画名称:">{{comicData.comicName}}</el-form-item>
        <el-form-item label="作品ID:">{{comicData.comicId}}</el-form-item>
        <el-form-item label="状态:">{{comicData.auditStatus | auditStatusFilter(level)}}</el-form-item>
        <el-form-item label="是否延迟:">{{comicData.deferStatus?'是':'否'}}</el-form-item>
        <el-form-item label="延迟理由:">{{comicData.deferDesc}}</el-form-item>
        <el-form-item label="漫画封面:">
            <img :key="$config.cdn + $utils.filterImagePath(comicData.comicCover34)" v-lazy="$config.cdn + $utils.filterImagePath(comicData.comicCover34)" class="audit-cover audit-cover-1" />
        </el-form-item>
        <el-form-item label="漫画封面:">
            <img :key="$config.cdn + $utils.filterImagePath(comicData.comicCover169)" v-lazy="$config.cdn + $utils.filterImagePath(comicData.comicCover169)" class="audit-cover audit-cover-2" />
        </el-form-item>
        <el-form-item label="漫画封面:">
            <img :key="$config.cdn + $utils.filterImagePath(comicData.comicCover43)" v-lazy="$config.cdn + $utils.filterImagePath(comicData.comicCover43)" class="audit-cover audit-cover-3" />
        </el-form-item>
        <el-form-item label="漫画作者:">{{comicData.authorName}}</el-form-item>
        <el-form-item label="作品介绍:">
            <el-tooltip effect="light" :content="comicData.comicDesc" placement="top">
                <div class="width100 single-ellipsis">{{comicData.comicDesc}}</div>
            </el-tooltip>
        </el-form-item>
         <el-form-item label="漫画等级:">{{ getGradeName(comicData.comicGrade) }}</el-form-item>
        <el-form-item label="连载状态:">{{comicData.serialNames.join(' ')}}</el-form-item>
        <el-form-item label="类型:">{{comicData.comicTypeNames.join(' ')}}</el-form-item>
        <el-form-item label="受众:">{{comicData.audienceNames.join(' ')}}</el-form-item>
        <el-form-item label="题材:">{{comicData.themeNames.join(' ')}}</el-form-item>
        <el-form-item label="标签:"></el-form-item>
        <ul class="tag-wrap">
            <li class="tag-item" v-for="category in (comicData.tag_list || comicData.auditComicTags)" :key="category.id">
                <template v-if="category.tags && category.tags.length">
                    <div class="tag-categroy" :title="category.category_name">{{category.category_name}}</div>
                    <div class="tag-tags">
                        <el-tag
                            :class="{'tag-tags-item': true, disabled: !tag.show_status }"
                            v-for="tag in category.tags"
                            :key="tag.tag_id"
                            size="medium"
                        >
                            <span class="tag-tags-item-inner" :title="tag.tag_name">{{tag.tag_name}}</span>
                        </el-tag>
                    </div>
                </template>
            </li>
        </ul>
    </el-form>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-29 17:52:38
 * @LastEditTime: 2018-09-29 17:52:38
 * @Description: 漫画详情-内容
 * @Company: 成都二次元动漫
 */

import { Grades } from '@/constants';
import { mapState } from 'vuex';
export default {
    props: {
        comicData: Object
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        })
    },
    data() {
        return {};
    },
    methods: {
        getGradeName(comicGrade) {
            const grade = Grades.find(item => item.value === comicGrade);
            return grade === undefined ? '未配置等级' : grade.label;
        }
    }
};
</script>

<style lang="scss" scoped>
    @import '../../../../styles/var';
    .audit-dialog-content{
        width: 450px;
        margin: 0 auto;
    }
    .audit-cover{
        border: 1px solid $border-color-base;
    }
    .audit-cover-1{
        width: 120px;
        height: 150px;
    }
    .audit-cover-2{
        width: 160px;
        height: 90px;
    }
    .audit-cover-3{
        width: 160px;
        height:120px;
    }
    .tag-wrap{
        padding: 10px 0;
        .tag-item{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            .tag-categroy{
                box-sizing: border-box;
                width: 90px;
                font-size: 14px;
                flex-basis: 90px;
                padding: 5px 12px 0 0;
                line-height: 30px;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .tag-tags{
                flex: 1 0;
                display: flex;
                flex-flow: row wrap;
                align-content: flex-start;
                &-item{
                    &-inner{
                        max-width: 150px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    margin: 5px;
                    &.disabled{
                        background: #ccc;
                        color: #fff;
                    }
                }
            }
        }
    }
</style>
