<template>
    <div class="audit-info page-container flex-column">
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @submit.native.prevent>
            <el-form-item v-if="activeIndex === '1'">
                <el-input v-model="searchData.chapterName" placeholder="章节名称" clearable class="input-with-select" @keyup.enter.native.prevent="searchHandle(1)">
                    <el-select v-model="searchData.type" slot="prepend" style="width:100px" placeholder="选择状态">
                        <el-option :key="item" v-for="item in ['全部', '新增', '编辑']" :label="item" :value="item"></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search" @click="searchHandle(1)"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item v-else-if="activeIndex === '2'">
                <el-input v-model="searchData.queryText" placeholder="修改、行为、字段、位置、关键词" clearable class="input-with-select" @keyup.enter.native.prevent="searchHandle(2)">
                    <el-button slot="append" icon="el-icon-search" @click="searchHandle(2)"></el-button>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="text" @click="clearSearchHandle">清除搜索</el-button>
            </el-form-item>
        </el-form>
        <div class="comic-dispatch-line"></div>
        <el-tabs v-model="activeIndex" class="audit-info-tab">
            <el-tab-pane label="待审核项" name="1">
                <auditComicMain ref="auditComicMain" @search="searchHandle"></auditComicMain>
            </el-tab-pane>
            <el-tab-pane label="审核记录" name="2">
                <auditComicRecord ref="auditComicRecord"></auditComicRecord>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-08-31 11:13:35
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-01 13:50:35
 * @Description: 审核漫画-详情
 * @Company: 成都二次元动漫
 */
import auditComicMain from './auditComicMain';
import auditComicRecord from './auditComicRecord';
import { getRouteByName } from '@/router/helper';

export default {
    data() {
        return {
            activeIndex: '1',
            searchData: {
                chapterName: null,
                queryText: null,
                type: '全部',
                comicId: null
            }
        };
    },
    beforeCreate() {
        const { commit, state } = this.$store;
        const auditComicListRoute = getRouteByName('auditComic');
        commit('app/setBreadcrumb', [...state.app.breadcrumb.slice(0, -1), ...auditComicListRoute, { title: this.$route.query.comicName }]);
    },
    mounted() {
        this.searchHandle();
    },
    methods: {
        // 搜索, 广播查询到子组件
        searchHandle(type) {
            const that = this;
            ['auditComicMain', 'auditComicRecord'].forEach((child, index) => {
                const { comicId } = this.$route.query;
                this.searchData.comicId = comicId;
                const _data = this.$utils.cloneDeep(this.searchData);
                that.$refs[child].searchEvent(_data);
            });
        },
        // 清除搜索
        clearSearchHandle() {
            this.searchData = this.$options.data().searchData;
            this.searchHandle();
        }
    },
    components: {
        auditComicMain,
        auditComicRecord
    }
};
</script>
<style lang="scss">
    .audit-info{
        &-tab{
            height: calc(100% - 93px);
            overflow-y: hidden;
            .el-tabs__content{
                height: calc(100% - 55px);
                .el-tab-pane{
                    height: 100%;
                    overflow: hidden;
                }
            }
        }
        .searchForm{
            text-align: center;
            .input-with-select{
                width: 490px;
            }
        }
    }
</style>
