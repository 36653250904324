<template>
    <div class="audit-main flex-column">
        <el-row :gutter="20" class="audit-main-option flex-row-between">
            <el-col :span="10">
                <span v-if="comicData.operateTime" class="audit-submit-time">提交时间： {{comicData.operateTime | timeFilter('yyyy年MM月dd日 hh:mm:ss')}}</span>
                <el-button :disabled="tableList.length !== 0" type="primary" size="mini" @click="forceAuditPass(comicData)">强制通过</el-button>
            </el-col>
            <el-col :span="14" align="right">
                <el-button size="small" v-if="[2,3].includes(level)" type="primary" @click="showDeffer()">延迟备注</el-button>
                <el-button type="primary" size="mini" @click="viewComicInfo(comicData)">漫画主体预览</el-button>
                <el-button
                    type="primary"
                    size="mini"
                    :disabled="checkDisablePassBtn"
                    @click="promptAudit('pass')"
                >通过</el-button>
                <el-button
                    type="danger"
                    size="mini"
                    :disabled="checkDisableNoPassBtn"
                    @click="promptAudit('reject')"
                >拒绝</el-button>
            </el-col>
        </el-row>
        <el-table
            :data="tableList"
            ref="table"
            v-loading="isloading"
            border
            @selection-change="handleSelectionChange"
            :row-class-name="tableRowClassName"
        >
            <el-table-column type="selection" label="选择" align="center" width="50"></el-table-column>
            <el-table-column prop="teamName" label="漫画团队"> </el-table-column>
            <el-table-column prop="editField" label="编辑字段"></el-table-column>
            <el-table-column prop="editAction" label="编辑行为"></el-table-column>
            <el-table-column prop="editDetails" label="编辑细节"><template slot-scope="scope">{{scope.row.editDetails.join(',')}}</template></el-table-column>
            <el-table-column prop="price" label="价格" width="50"> </el-table-column>
            <el-table-column prop="comicGrade" label="漫画等级" width="50">
                <template>
                    {{ getGradeName }}
                </template>
            </el-table-column>
             <el-table-column label="章节封面图" prop="chapterCover168" align="center" width="80">
                <template slot-scope="scope">
                    <img-preview :src="$config.cdn + $utils.filterImagePath(scope.row.chapterCover168)">
                       <img
                            width="76px"
                            height="38px"
                            :key="$config.cdn + $utils.filterImagePath(scope.row.chapterCover168)"
                            v-lazy="$config.cdn + $utils.filterImagePath(scope.row.chapterCover168)"
                        />
                    </img-preview>
                </template>
            </el-table-column>
            <el-table-column prop="gridNum" label="格子数" width="50"> </el-table-column>
            <el-table-column prop="imageNum" label="图片数" width="50"> </el-table-column>
            <el-table-column label="审核状态">
                <template slot-scope="scope">
                    <!-- 主体和章节状态取不同字段 -->
                    <span v-if="!isComic(scope.row)" :class="{'color-red': scope.row.auditStatus === 3}">{{ scope.row.auditStatus | auditStatusFilter(level) }}</span>
                    <span v-if="isComic(scope.row)" :class="{'color-red': scope.row.compareStatus === 3}">{{ scope.row.compareStatus | auditStatusFilter(level) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="auditRemark" label="最新审核理由">
                <template slot-scope="scope">
                      <el-tooltip  class="item" effect="light" :content="auditShowRemark(scope.row)" placement="top-start">
                        <div class="single-ellipsis">{{ auditShowRemark(scope.row) }}</div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column label="是否定时发布">
                 <template slot-scope="scope">
                    {{ getPublishTimerInfo(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column label="发布时间" width="200">
                <template slot-scope="scope">
                    <div v-for="platform in scope.row.tasks" :key="platform.platformId" class="platform-col flex-row-between">
                        <div class="platform-col-name">{{formatPlatform(platform.platformId)}}</div>
                        <div v-if="platform.publishTime" class="platform-col-time">{{platform.publishTime | timeFilter('yyyy-MM-dd hh:mm:ss')}}</div>
                        <div v-else class="platform-col-time">立即发布</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column width="110">
                <template slot="header" slot-scope="scope">
                    图片鉴黄状态
                    <el-tooltip content="长时间处于【鉴黄中】无法处理的漫画章节可以重新刷新页面试试哦" placement="top-end">
                        <i class="el-icon-question"></i>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    {{scope.row.greenDesc | greenDescFilter}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="text" @click="previewHandle(scope.row)">预览</el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialogPlus :visible.sync="dialogData.visible" :title.sync="dialogData.title" width="700px" :model="dialogData.data" :heightFull="true">
            <auditComicDialog :comicData="dialogData.data" />
        </dialogPlus>
        <chapterView
            ref="chapterView"
            :chapterId.sync="chapterId"
            :comicName="$route.query.comicName"
            :comicId="$route.query.comicId"
        ></chapterView>
        <dialogPlus
            center
            :visible.sync="editDeferData.visible"
            title="延迟备注"
            width="400px"
            :heightFull="false"
            :close-on-click-modal="false"
        >
            <el-form ref="deferInfoForm" :model="editDeferData.data" :rules="editDeferData.rules" label-width="50px">
                <el-form-item label=" " prop="deferDesc">
                    <el-input
                        style="width:300px"
                        type="textarea"
                        :autosize="{ minRows: 6 }"
                        v-model.trim="editDeferData.data.deferDesc"
                        placeholder="请输入备注"
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" align="center">
                <el-button @click="closeDeferInfo">取消</el-button>
                <el-button type="primary" @click="saveDeferInfo">确定</el-button>
            </div>
        </dialogPlus>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-08-31 11:10:58
 * @LastEditTime: 2018-08-31 19:01:18
 * @Description: 漫画审核-审核主体
 * @Company: 成都二次元动漫
 */

import { dialogMixin, comicMixin } from '@/mixins';
import auditComicDialog from './auditComicDialog';
import chapterView from '@/views/main/comicManager/comicChapter/chapterView';
import { Grades } from '@/constants';
import ImgPreview from '@/components/img-preview';
import { mapState } from 'vuex';

export default {
    mixins: [dialogMixin, comicMixin],
    props: {
        search: Function
    },
    data() {
        return {
            chapterId: null,
            isloading: false,
            comicData: {},
            tableList: [],
            searchData: {},
            pubClass: [],
            auditStatus: null, // 待提交：1 、待审核：2 、审核拒绝：3 、审核通过:4
            selectedRows: [], // 选择的行
            platformList: [], // 平台列表
            editDeferData: { // 延迟备注
                visible: false,
                btnDisabled: true,
                data: {
                    deferDesc: null
                },
                rules: {
                    deferDesc: [
                        { required: true, message: '请填写备注', trigger: 'blur' }
                    ]
                }
            }
        };
    },
    created() {
        this.getPublicClass().then(res => (this.pubClass = res));
    },
    mounted() {
        this.getComicPlatformList();
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管, 4:cp方审核人员
        }),
        checkDisablePassBtn() { // 通过按钮
            if (this.selectedRows.length === 0) {
                return true;
            }
            // 如果是一审人员则不做置灰
            if (this.level === 4) {
                return false;
            }
            let flag = this.selectedRows.filter(item => {
                return ['ing'].includes(item.greenDesc);
            }).length;

            if (flag) {
                return true;
            }
        },
        checkDisableNoPassBtn() { // 拒绝按钮
            // 主体审核状态是否有未通过的
            const noSuccMainRow = this.selectedRows.filter(item => this.isComic(item) && item.compareStatus === 3);

            // 章节审核状态是否有未通过的
            const noSuccCapRows = this.selectedRows.filter(item => !this.isComic(item) && item.auditStatus === 3);
            if (noSuccMainRow.length > 0 || noSuccCapRows.length > 0) { // 如果选中有拒绝的，则灰掉按钮
                return true;
            }
            return this.selectedRows.length === 0;
        },
        getGradeName() {
            const grade = Grades.find(item => item.value === this.comicData.comicGrade);
            return grade === undefined ? '未配置等级' : grade.short;
        }
    },
    methods: {
        closeDeferInfo() {
            this.editDeferData.visible = false;
        },
        saveDeferInfo() {
            this.$refs.deferInfoForm.validate((valid) => {
                if (!valid) {
                    return;
                }

                const { comicId } = this.comicData;
                const { deferDesc } = this.editDeferData.data;
                this.$api('deferInfo', {
                    comicId,
                    deferStatus: 1,
                    deferDesc
                }).then((res) => {
                    this.$message.success('备注成功');
                    this.editDeferData.visible = false;
                    this.$emit('search');
                });
            });
        },
        showDeffer() {
            this.editDeferData.data.deferDesc = this.comicData.deferDesc;
            this.editDeferData.visible = true;
        },
        // 根据主体|章节显示对应备注
        auditShowRemark(row) {
            return this.isComic(row) ? row.compareRemark : row.auditRemark;
        },
        // 是否是漫画主体
        isComic(row) {
            return row.type === 'comic';
        },
        // 获取章节是否定时发布
        getPublishTimerInfo(row) {
            let info = '否';
            if (row.tasks && row.tasks.length > 0) {
                const re = row.tasks.some(item => item.isTiming);
                if (re) {
                    info = '是';
                }
            }
            return info;
        },
        // 获取漫画类型列表
        getComicPlatformList() {
            return this.$api('getComicPlatformList').then(({ data }) => (this.platformList = data));
        },
        // 格式化平台名称
        formatPlatform(platformId) {
            const platform = this.platformList.find(platform => (platform.id === platformId));
            if (platform) {
                return platform.name;
            }
            return '';
        },
        handleSelectionChange(val) {
            this.selectedRows = val;
        },
        searchEvent(searchData) {
            this.searchData = searchData;
            this.getTableList();
        },
        // 审核操作
        promptAudit(type) {
            let url, message, title, inputValue;
            const defaultInput = {
                '2': '二审同意',
                '4': '一审同意'
            };

            if (type === 'pass') {
                url = 'passAudit_Admin';
                message = '确定通过审核吗？';
                title = '审核通过';
                inputValue = defaultInput[this.level];
            } else if (type === 'reject') {
                url = 'refuseAudit_Admin';
                message = '确定拒绝通过审核吗？';
                title = '拒绝通过';
            }

            const { comicId, comicName, uploaderUid, authorName } = this.comicData;

            this.$prompt(message, title, {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                inputPlaceholder: '请输入理由',
                showInput: true,
                inputValue,
                inputType: 'textarea',
                customClass: 'audit-main-msgbox',
                center: true,
                inputValidator(value) {
                    if (!value) return '请输入理由';
                    else if (value.length > 100) return '输入字符数量1-100';
                    return true;
                },
                beforeClose: (action, instance, done) => {
                    const closeHandle = () => {
                        instance.confirmButtonLoading = false;
                        done();
                    };
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = '提交中...';
                        // 如果有主体审核，单独用hasMain标识
                        const hasMain = this.selectedRows.some(item => item.chapterId === 0);
                        const chapterIds = this.selectedRows.filter(item => item.chapterId !== 0)
                            .map(item => item.chapterId).join(',');
                        this.$api(url, { chapterIds, comicId, auditRemark: instance.inputValue, uploaderUid, comicName, authorName, hasMain }).then(res => {
                            this.$message.success('提交成功！');
                            this.$emit('search');
                            closeHandle();
                        }).finally(() => {
                            instance.confirmButtonLoading = false;
                            instance.confirmButtonText = '提交';
                        });
                    } else {
                        closeHandle();
                    }
                }
            });
        },
        forceAuditPass({ comicId }) {
            this.$api('forceAuditPass', { comicId }).then(({ data }) => {
                this.$message.success('操作成功');
            });
        },
        // 查看漫画详情
        viewComicInfo(row) {
            this.changeDialog(true, '', 0, { ...this.comicData, ...this.$utils.formatPublicClass(this.pubClass[0].child, this.comicData.classIdlist.split(',')) });
        },
        tableRowClassName() {
            return this.comicData.deferStatus ? 'has-deffer' : '';
        },
        // 预览章节
        previewHandle(row) {
            const { chapterId } = row;

            if (this.isComic(row)) {
                this.viewComicInfo(row);
                return false;
            }
            this.chapterId = chapterId;
            this.chapterIsView = true;
            this.$refs.chapterView.openDialog();
        },
        // 查询列表
        getTableList() {
            const auditStatus = this.$route.query.auditStatus;
            this.isloading = true;
            this.$api('getComicDiff_Admin', { comicId: this.searchData.comicId, auditStatus }).then(({data}) => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                this.comicData = data;
                this.tableList = this.selectHandle(data.rows, this.searchData);
                this.isloading = false;
            });
        },
        // 前端查询
        selectHandle(list, searchData) {
            const { type, chapterName } = searchData;
            if (type !== '全部') {
                list = list.filter(item => item.editAction === type);
            } else if (chapterName) {
                list = list.filter(item => {
                    const chapterNameArray = item.chapterName.split('');
                    return chapterName.split('').some(b => chapterNameArray.includes(b));
                });
            }
            return list;
        }
    },
    components: {
        chapterView,
        auditComicDialog,
        ImgPreview
    }
};
</script>
<style lang="scss">
    .audit-main{
        height: 100%;
        &-option{
            margin-bottom: 10px;
        }
        .el-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
            .has-deffer{
                td {
                    background: #f5ecec;
                }
            }
        }
        .audit-submit-time{
            height: 32px;
            line-height: 32px;
            font-size: 14px;
        }
    }
    .audit-main-msgbox{
        .el-message-box__title span{
            color: red;
            font-size: 20px;
        }
    }
</style>
